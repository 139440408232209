.singleEntity-dialog-wrapper {
  @apply p-4
  mx-4
  mb-4;
}
.singleEntity-dialog-wrapper .group {
  @apply mb-3 p-2;
}

.singleEntity-dialog-wrapper .row {
  @apply flex
    flex-row
    flex-wrap
    w-full
    mb-0;
}

.singleEntity-dialog-wrapper .notFound-layout {
  @apply flex flex-col
  justify-center items-center
  mt-5;
}
