.customRangeValueSlider-wrapper {
  @apply w-[230px];
}

.customRangeValueSlider-label {
  @apply text-[13.5px]
  leading-5
  h-9
  flex
  items-center
  w-[255px]
  !important;
}

.MuiSlider-markLabel {
  @apply top-6 !important;
}

.MuiSlider-root span[aria-hidden='true'][data-index='1'].MuiSlider-markLabel {
  @apply -translate-x-full;
}
