.row {
  @apply flex
    flex-row
    flex-wrap
    w-full;
}

.column {
  @apply flex
    flex-col
    basis-full
    mb-5;
}

label {
  @apply font-bold
    mr-2;
}

.showValuesButton {
  @apply mx-auto
    my-0
    uppercase;
}
