.Full-Hero-main {
  @apply h-screen w-screen
        bg-cover bg-no-repeat bg-center
        flex justify-center items-center
        fixed left-0 top-0
    !important;
}

.Full-Hero-opacity-filter {
  @apply h-screen w-screen
        absolute left-0 top-0
        pointer-events-none
    !important;
}

.Full-Hero-children {
  @apply z-10
    !important;
}
