.singleEntity-container .wrapper .showValuesButton {
  @apply mx-auto
    my-0
    uppercase;
}

.singleEntity-container .notFound-layout {
  @apply flex flex-col
  justify-center items-center
  mt-5;
}

.singleEntity-container .breadcrumb-container {
  @apply flex
  flex-col;
}
.singleEntity-container .breadcrumb-container h2 {
  @apply text-[35px]
    leading-[50px]
    text-[color:var(--paletteGreen)]
    px-4
    pb-1
    !important;
}

.singleEntity-container .breadcrumb-container .breadcrumb {
  @apply border-2
  border-[color:var(--paletteGreen)]
  border-solid
  rounded-xl
  w-fit
  ml-4
  mb-4
  px-2
  py-1.5
  pl-8;
}

.singleEntity-container .searchInput {
  @apply mx-4;
}
