@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer base {
  :root {
  --paletteYellow: #dba507;
  --paletteGreen: #4fa500;
  --paletteLighterGreen: #50a50080;
  --paletteLightestGreen: #50a50020;
  --paletteDarkGreen:#1B6600;
  --paletteLightGreen:#A9EC62;
  --paletteLightOrange: #fdd19b;
  --paletteLighterOrange: #FBAC4B;
  --paletteOrange: #fa951d;
  --paletteDarkOrange: #b46504;
  --paletteRed: #d50000;
  --palettelightBlue: #8ec7d2;
  --paletteBlue: #0d6986;
  --paletteDarkBlue: #07485b;
  --paletteLightGray: #fafafa;
  --paletteGray: #797979;
  --paletteLightGrayDelimiter: #cacaca;
  --paletteDarkGrayDelimiter: #1f1f1f;
  --paletteDarkBlack: #121212;
  --topBarHeight: 3.125rem;
  --mainMenuWidth: 9rem;
  --mainMenuWidthMobile: 4rem;
  }
}
  h5 {
  font-size: 1.65rem !important;
}

.MuiTablePagination-root p {
  line-height: 2;
}
