.searchInput:hover {
  box-shadow: 0px 0px 5px var(--paletteGreen) !important;
}

.searchInput:focus-within {
  box-shadow: 0px 3px 1px -2px rgba(79, 165, 0, 0.2),
    0px 2px 2px 0px rgba(79, 165, 0, 0.14),
    0px 1px 5px 0px rgba(79, 165, 0, 0.12);
}
.ArrowBackIconWrapper {
  @apply flex 
  items-center 
  justify-center;
}
.ArrowBackIconWrapper:hover,
.SearchIconWrapper {
  @apply cursor-pointer;
}
.SearchIconWrapper {
  @apply flex 
  items-center 
  justify-center;
}
