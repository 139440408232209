.search-params.css-142lwrn-MuiPaper-root-MuiAccordion-root.Mui-expanded,
.search-params.css-142lwrn-MuiPaper-root-MuiAccordion-root {
  @apply rounded-2xl
  !important;
}

.search-params.css-142lwrn-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  @apply m-0;
}

.search-params-details.css-15v22id-MuiAccordionDetails-root {
  @apply pb-2
  pr-4
  pl-4
  !important;
}
.datahub-wrapper {
  @apply flex 
  flex-wrap 
  gap-y-3
  gap-x-7;
}
