.Data-Explorer-main {
    @apply
        p-6
    !important;
}

.Data-Explorer-form-container {
    @apply
        w-full
    !important;
}

.Data-Explorer-form {
    @apply
        flex space-y-6
    !important;
}

.Data-Explorer-text-field {
    @apply
        md:w-5/6 w-full
    !important;
}

.Data-Explorer-option-label {
    @apply
    !important;
}

.Data-Explorer-button-container {
    @apply
        flex justify-end
        mt-4
    !important;
}